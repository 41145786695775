import { Fragment, useEffect, useState } from "react";
import ReactDOM, { unmountComponentAtNode, createPortal } from 'react-dom';
import $ from 'jquery';
import VGPopUp from "./VGPopUp";
import { useNavigate } from "react-router-dom";
export const NewFeatures = {
    Reaction: {
        id: 1,
        Show: false,
    },
    AI: {
        id: 2,
        Show: false,
    },
};
export const NewFeaturesString = Object.keys(NewFeatures).join(',');
export const NewFeaturesList = Object.keys(NewFeatures);
export const FeaturePopup = ({ Name, onClose, SettingNavigate }) => {
    const handlepopupClose = () => {
        switch (Name) {
            case "Reaction":
                $("body").removeClass("emoji-banner-open");
                break;
            case "AI":
                $("body").removeClass("ai-banner-open");
                break;
            default:
                break;
        }
        if (onClose) {
            onClose();
        }
        unmountComponentAtNode(document.getElementById("OpenDialog1"));
    };
    const PopupTitle = () => {
        switch (Name) {
            case "Reaction":
                return <></>
            case "AI":
                return <></>
            default:
                return <></>
        }
    };
    const PopupBody = () => {
        switch (Name) {
            case "Reaction":
                return (
                    <Fragment>
                        <div className='emoji-banner-wrap'>
                            <div className='emoji-img-box'>
                                <img src="https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/emojis-banner-img-new.png" alt="" />
                            </div>
                            <h2 className='emoji-banner-heading'>Express More with Emojis</h2>
                            <p className='emoji-banner-desc'>Your messages just got more expressive! Instantly share your thoughts, feelings, and reactions with just a tap. Try it out today and connect on a whole new level!</p>
                        </div>
                    </Fragment>
                )
            case "AI":
                return (
                    <Fragment>
                        <div className='ai-banner-popup-wrap'>
                            <div className='ai-banner-popup-box'>
                                <img src="https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/connectAibannerimg.png" width="424" alt="" /></div>
                            <h2 className='ai-banner-popup-heading'>Try Connect AI for Connect</h2>
                            <p className='ai-banner-popup-desc'> Enhance your business with Connect AI as your virtual assistant! Free up your time and energy as Connect AI instantly handles customer responses and increases your bookings.</p>
                        </div>
                    </Fragment>
                )
            default:
                return <></>
        }
    };
    
    const Footer = () => {
        switch (Name) {
            case "Reaction":
                return (
                    <Fragment>
                        <button className="btn btn-primary vcgradientbtn vcprimary-btn" onClick={() => handlepopupClose()}>
                            <div className='vcsecondaryInner'>OK</div>
                        </button>
                    </Fragment>
                );
            case "AI":
                return (
                    <Fragment>
                        <button className="btn btn-primary vcgradientbtn vcprimary-btn" onClick={() => { handlepopupClose(); SettingNavigate(); }}>
                            <div className='vcsecondaryInner'>
                                Get Started
                            </div>
                        </button>
                        <button className="btn btn-primary vcgradient-border-btn vcprimary-btn" onClick={() => handlepopupClose()}>
                            <span>
                                Maybe Later
                            </span>
                        </button>
                    </Fragment >
                );
            default:
                return <></>
        }
    };
    const popUpShow = () => {
        setTimeout(() => {
            return true
        }, 100);
    };
    const HandleClassName = () => {
        switch (Name) {
            case "Reaction":
                return "vgn-modal-alert vgn-modal-small modal-mb-half emoji-banner-popup";
            case "AI":
                return "vgn-modal-alert vgn-modal-small modal-mb-half upgradeText-modal newFeatureAiPopup";
            default:
                return "vgn-modal-alert vgn-modal-small modal-mb-half textingUnlocked-modal";
        }
    };

    return createPortal(
        <VGPopUp
            show={popUpShow}
            title={PopupTitle()}
            body={PopupBody()}
            onHide={handlepopupClose}
            footer={Footer()}
            dialogClassName={HandleClassName()}
            isShowFooter={true}
            isStatic={true}
        />,
        document.getElementById("OpenDialog1")
    );
};
export const NewFeaturePopup = ({ SettingNavigate }) => {
    const [convertionData, setConvertionData] = useState(true);
    const showNextPopup = () => {
        setConvertionData(true);
    }
    useEffect(() => {
        if (convertionData) {
            var first = true;
            NewFeaturesList.map(name => {
                if (NewFeatures[name].Show && first) {
                    ReactDOM.render(
                        <FeaturePopup
                            Name={name}
                            onClose={showNextPopup}
                            SettingNavigate={SettingNavigate}
                        />,
                        document.getElementById("OpenDialog1")
                    );
                    first = false;
                    NewFeatures[name].Show = false;
                    setConvertionData(false);
                }
                return "";
            })
        }
    }, [convertionData]);

    return <></>;
};

