import React, { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { ChatbodSelectionData, textdata } from '../Binding/StenciedData';
import * as signalR from '@microsoft/signalr';
import { CommonMethods, ConnectionStatus, SelectionControlTypeEnum, StatusEnum, ThunkApiEnum, VagaroConnectMessageType } from '../Binding/ChatBoatEnums';
import { utils } from '../../utils/Utils';
import { useDispatch, useSelector } from 'react-redux';
import { UpdateTypingUser } from '../../component/Slice/TypingUser';
import { fetchAISettings, ThunkAPI } from '../../component/Common/AsyncThunk';
import { setActivate } from '../../component/Slice/BusinessSettings';
//import {VagaroConnectSignalRURL} from '../../component/Common/APIURL';

const ChatContext = createContext();

export const ChatProvider = ({ children , newChat }) => {

    const Dispatch = useDispatch();
    const { Status: Settings_Status, Activate: AiSetting_Activate, Pause: AiSetting_Pause, AISettings: AiSetting_AISettings, CustomHours: AiSetting_CustomHours, businessoffhours: AiSetting_businessoffhours } = useSelector((state) => state.Settings);
    const { Status: TextMarketing_Status, TextMarketingEnable: TextMarketing_Enable } = useSelector((state) => state.TextMarketing);
    const [connection, setConnection] = useState(null);
    const [connectionStatus, setConnectionStatus] = useState('Connecting...');

    const [chatSelectionID, setChatSelectionID] = useState(ChatbodSelectionData.Id);
    const [chatSelection, setChatSelection] = useState(ChatbodSelectionData);
    const [AIControl, setAIControl] = useState(false);
    const [renderState, renderPage] = useState(false);
    const [conversations, setconversations] = useState([]);
    const [LoadPages, setLoadPage] = useState([]);
    const [CommonMethod, SetMethods] = useState(CommonMethods);
    const [isCustomer, setIsCustomer] = useState(false);
    const CommonMethodRef = useRef(CommonMethods);
    const [textmarketing, setTextmarketing] = useState(textdata);
    //const [lastActiveTime, setLastActiveTime] = useState(null);
    const StoreBadWordString = useRef(null);
    const smsavail = useRef(null);
    const FirstUserData = useRef("");
    const TakeCantrolRef = useRef(null);
    const UserTextMarketingData = useRef();
    const storeEmployeeData = useRef();
    const ScrollisBottom = useRef(false);
    const doRender = () => {
        renderPage(!renderState);
    }

    const ChatboadSelection = (type, id, conversationId, displayname, image, data, isFromGroup = false, isBlocked = false) => {
        var temp = ChatbodSelectionData;
        temp.Type = type
        temp.Id = id;
        temp.ConversationId = conversationId;
        temp.Displayname = displayname;
        temp.Image = image || temp.Image;
        temp.AllData = data || temp.AllData;
        temp.IsFromGroup = isFromGroup;
        temp.IsBlocked = isBlocked;
        if (temp.AllData?.takeControl) { TakeCantrolRef.current = temp.AllData?.takeControl }
        setChatSelectionID(temp.Id);
        setChatSelection(temp);
        doRender();
    }
    const addconversations = (conversationId, response) => {
        var prevResponses = conversations;
        var PreLoadPages = LoadPages;
        prevResponses[conversationId] = prevResponses[conversationId] || [];
        if (response) {
            if (prevResponses[conversationId].length > 0) {
                prevResponses[conversationId] = response.concat(prevResponses[conversationId]);
                setLoadPage(PreLoadPages[conversationId] + 1);

            } else {
                prevResponses[conversationId] = response;
                PreLoadPages[conversationId] = 1;
            }
        }
        setconversations(prevResponses);
    };
    window.JoinToGroupCustomer = (connection, custHashId) => {
        connection.invoke("JoinGroup", custHashId + window.ReactBusinessId, "CUSTOMER_JOINED");
        //connection.invoke("CustomerJoinGroup", window.ReactBusinessId, custHashId + window.ReactBusinessId);
    };
    window.JoinToGroupBusiness = (connection, custHashId) => {

        //console.log("connection started");
        //console.log("join group businessID");
        connection.invoke("JoinGroup", custHashId + window.ReactBusinessId, "BUSINESS_JOINED");
    };
    const LeaveFromGroup = (groupName, message) => {
        connection.send('LeaveGroup', groupName, message);
    };

    const AddCommonMethod = (name, method = () => { }) => {
        const methods = CommonMethod;
        methods[name] = (...param) => {
            try {
                return method(...param);
            } catch (error) {
                console.log(error);
            }
        };
        SetMethods(methods);
        CommonMethodRef.current = methods;
    }
    const Addtextmarketing = (val) => {
        setTextmarketing(val)
    }

    const issmsavail = (val) => {
        smsavail.current = val;
    }

    const SetFirstUserData = (Data) => {
        FirstUserData.current = Data;
    }

    const StoreTextMarketingData = (Data) => {
        UserTextMarketingData.current = Data;
    }

    const badWordStore = (Data) => {
        StoreBadWordString.current = Data;
    }
    const EmployeeDataStore = (Data) => {
        storeEmployeeData.current = Data;
    }
    const GetEmployeeIdFromHas = (id) => {
        let memberID = storeEmployeeData.current?.find(arr => arr.memberIdHash === id)?.memberId;
        if (memberID) {
            return memberID;
        }
        return id;
    }
    
    
    const chatContextValue = {
        renderState,
        chatSelectionID,
        chatSelection,
        ChatboadSelection,
        conversations,
        addconversations,
        connection,
        connectionStatus,
        CommonMethodRef,
        CommonMethod,
        AddCommonMethod,
        Addtextmarketing,
        textmarketing,
        setChatSelection,
        setIsCustomer,
        smsavail,
        issmsavail,
        setConnection,
        SetFirstUserData,
        FirstUserData,
        StoreTextMarketingData,
        UserTextMarketingData,
        badWordStore,
        StoreBadWordString,
        EmployeeDataStore,
        storeEmployeeData,
        GetEmployeeIdFromHas,
        ScrollisBottom,
        setChatSelectionID,
        AIControl,
        setAIControl,
    };


    const startConnection = () => {
        if (connection == null) {
            try {
                const newConnection = new signalR.HubConnectionBuilder()
                    .withUrl(window.VagaroConnectSignalRURL + 'connect/business-chat')
                    .withAutomaticReconnect([0, 2000, 2000, 2000, 2000, 2000, 5000, 5000, 10000])
                    .build();

                newConnection.onclose((error) => {
                    setConnectionStatus(ConnectionStatus.Closed);
                    console.log("Connection Closed");
                    if (error !== undefined) {
                        //console.error('Connection closed:', error);
                    }
                    // connection.start();
                });

                newConnection.onreconnecting(() => {
                    setConnectionStatus(ConnectionStatus.Reconnecting);
                });

                newConnection.onreconnected(() => {
                    setConnectionStatus(ConnectionStatus.Connected);
                });
                newConnection.on('ReceivedMessage', (data) => {
                    console.log('ReceiveMessage', data);
                    window.SetMessageCopy(data);
                });
                newConnection.on('TypingUserUpdate', (data) => {
                    if (data && data.messageType === VagaroConnectMessageType.UserTyping) {
                        if (utils.isNullEmptyOrWhiteSpace(data.conversationId)) return;
                        let Payload = {
                            id: data.conversationId,
                            userId: data.userId,
                            fullName: data.fullName,
                            url: data.image,
                            LastUpdateTime: null,
                            AI: data.isai
                        }
                        Dispatch(UpdateTypingUser(Payload));
                    }
                });
                newConnection.on('AiBroadCast', (data) => {
                    if (data) {
                        switch (data.messageType) {
                            case VagaroConnectMessageType.AIActive:
                                Dispatch(fetchAISettings())
                                break;
                            case VagaroConnectMessageType.TakeControl:
                                window.setAiBroadCast(data);
                                break;
                            default:
                                break;
                        }
                    }
                });
                newConnection.on('VG_BroadcastMassage', (data) => {
                    //console.log('VG_BroadcastMassage', data);
                    var jsonData = JSON.stringify(data).replace(/\\\"/g, '"').replace(/"/g, "\\\"");
                    window.parent.postMessage('{"eventType": "VG_BroadcastMassage","Data": "' + jsonData + '"}', '*');
                });

                newConnection.on("ReceivedFromCustomerToBusiness", (data) => {
                    if (!isCustomer) {
                        console.log('ReceivedFromCustomerToBusiness', data);
                        window.OpenConnectFromTwilio(data);
                    }
                });
                newConnection.on("SendSMSStatusToBusiness", (data) => {
                    console.log('SendSMSStatusToBusiness', data);
                });

                newConnection.start({ pingInterval: 6000 })
                    .then(() => {
                        setConnectionStatus(ConnectionStatus.Connected);
                        utils.appInsightTrack(
                            window.ReactBusinessId,
                            window.ReactUserID,
                            "Connection:Start",
                            "",
                            "Business Connected"
                        );

                    })
                    .catch(err => {
                        setConnectionStatus(ConnectionStatus.Failed);
                        console.error('Error connecting to SignalR:', err);
                        setTimeout(newConnection.start(), 3000);
                        utils.appInsightTrack(
                            window.ReactBusinessId,
                            window.ReactUserID,
                            "Connection:Error",
                            "",
                            "Some Error Occured"
                        );
                    });

                setConnection(newConnection);
            } catch (error) {
                setTimeout(() => startConnection(), 5000);
            }
        }
    }

    useEffect(() => {
        startConnection();
    }, [connection]);
    //Tab Active - STart
    //useEffect(() => {
    //    let timerId;
    //        // Handle tab visibility changes
    //    const handleVisibilityChange = () => {
    //        setTimeout(() => {

    //            //console.log("visiblity: " + document.visibilityState)
    //            if (document.visibilityState === 'visible') {
    //                // Tab is active, connect SignalR
    //                //console.log("connection details: " + connection)
    //                if (connection != null) {
    //                   // console.log("connection state: " + connection.state)
    //                } else {
    //                    //console.log("connection state: null")
    //                }

    //                if (connection && connection.state !== "Connected") {
    //                    connection.start()
    //                        .then(() => {
    //                            setConnectionStatus(ConnectionStatus.Connected);
    //                            connection.invoke("JoinGroup", window.ReactBusinessId + "", "BUSINESS_JOINED");
    //                            console.log("active tab connected")


    //                        })
    //                        .catch(err => {
    //                            setConnectionStatus(ConnectionStatus.Failed);
    //                        });
    //                    console.log("start again");
    //                    lastActiveTime.current = null;
    //                }

    //            } else {
    //                // Tab is inactive, disconnect SignalR
    //                //connection.stop();
    //                lastActiveTime.current = Date.now();
    //                timerId = setInterval(checkInactiveTime, 250);
    //                //console.log("stop");
    //            }
    //        }, 1000);

    //        };

    //    document.addEventListener('visibilitychange', handleVisibilityChange);

    //        return () => {
    //            document.removeEventListener('visibilitychange', handleVisibilityChange);
    //            clearInterval(timerId);
    //        };
    //}, [connection])

    //const checkInactiveTime = () => {
    //    const currentDate = new Date();
    //    if (lastActiveTime.current !== null && currentDate - lastActiveTime.current > 30 * 60 * 1000) {
    //        // Disconnect SignalR if tab has been inactive for 10 minutes
    //        if (connection.state === "Connected") {
    //            setConnectionStatus(ConnectionStatus.Closed);
    //            connection.stop();
    //            console.log("connection stop");
    //            lastActiveTime.current = null;
    //        }
    //    }
    //};
    //Tab Active - End


    //const handleActiveTab = () => {
    //    // Update last active time when tab becomes active
    //    //setLastActiveTime(Date.now());
    //};

    //useEffect(() => {
    //    // Listen for tab focus to update last active time
    //    window.parent.addEventListener('focus', () => { debugger; setLastActiveTime(null) });
    //    window.parent.addEventListener('blur', () => { debugger; setLastActiveTime(Date.now()) });

    //    return () => {
    //        window.parent.addEventListener('focus', () => { debugger; setLastActiveTime(null) });
    //        window.parent.addEventListener('blur', () => { debugger; setLastActiveTime(Date.now()) });
    //    };
    //}, []);
    //useEffect(() => {
    //    console.log(connectionStatus);
    //    if (connectionStatus != null && connectionStatus === 'Connected' && convertionData !== undefined && convertionData !== null && convertionData.length > 0) {
    //        //            window.JoinToAllConversations(connection)
    //        console.log("start");

    //        convertionData.forEach(async (data) => {
    //            window.JoinToGroupBusiness(connection, data.customerIdHash);
    //        })

    //        console.log("stop");
    //    }
    //}, [connectionStatus, convertionData]);
    useEffect(() => {
        if (Settings_Status === StatusEnum.Idle) {
            ThunkAPI(Dispatch, ThunkApiEnum.AiSetting);
        }
    }, [Settings_Status, Dispatch]);
    useEffect(() => {
        if (TextMarketing_Status === StatusEnum.Idle) {
            ThunkAPI(Dispatch, ThunkApiEnum.TextMarketing);
        } else if(TextMarketing_Status === StatusEnum.Done) {
            if (!TextMarketing_Enable && AiSetting_Activate ) {
                Dispatch(setActivate(false))
                ThunkAPI(Dispatch, ThunkApiEnum.UpdateSetting);
            }
        }
    }, [TextMarketing_Status, TextMarketing_Enable, Dispatch]);
    

    return (
        <ChatContext.Provider value={chatContextValue}>
            {children}
        </ChatContext.Provider>
    );
};

// Create a custom hook to access the chat context
export const useChatContext = () => {
    const context = useContext(ChatContext);
    if (!context) {
        throw new Error('useChatContext must be used within a ChatProvider');
    }
    return context;
};
