import React, { Fragment, useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { setActivate, SetSettingControl, toggleDay, toggleWorkingDaysVisibility, toggleCustomHours, HandleCopyToAll, handleCustomHours, SetHoursValidation, setPause, updateCustomHoursFromResponse, toggleWorkingDays, ResetCustomHours } from '../../../component/Slice/BusinessSettings';
import { AIContentEnum, AISettingEnum, AiSettingModuleId, CustomHoursEnum, MobileCallBackAction, StatusEnum, ThunkApiEnum } from '../../Binding/ChatBoatEnums';
import { isAndroidIOSTabIpad, utils } from '../../../utils/Utils';
import { ThunkAPI } from '../../../component/Common/AsyncThunk';
import TimePicker, { roundToNearestFive } from './TimePicker';
import VGPopUp from '../../../component/Common/VGPopUp';
import ReactDOM, { unmountComponentAtNode } from 'react-dom';
import { useVagaroAIContext } from '../../Store/VagaroAIContext';
import { useChatContext } from '../../Store/ChatProvider';
import { AsyncAjaxGet } from '../../../component/Common/AsyncAjaxServiceV2';
import useGenerateToken from '../../../component/Hooks/useGenerateToken';
import { DeactiveAi, TriggerEnableTextMaketing } from '../../../component/Slice/TextMarketingSlice';

export const Status = () => {
    const show = useSelector((state) => state.Settings.Activate);
    const paused = useSelector((state) => state.Settings.Pause);
    switch (show) {
        case true:
            return (paused ? <div className='ai-badge-warning mob-app-hide-text'><span className='statusText'>Status:&nbsp;</span>Paused</div> : <div className='ai-badge-success mob-app-hide-text'><span className='statusText'>Status:&nbsp;</span>Active</div>)
        case false:
            return <></>
        default:
            return <></>

    }
};
export const DeactivePopup = (callback = () => { }) => {
    const AlertClose = () => {
        unmountComponentAtNode(document.getElementById("OpenDialog"));
    };
    const HandleButton = (val) => {
        callback(val);
        AlertClose()
    };
    const AlertTitle = () => {
        return (
            <Fragment>
                <Fragment>Deactivate Connect AI</Fragment>
            </Fragment>
        )
    };
    const AlertBody = () => {
        return (
            <div>
                <Fragment>
                    Are you sure you want to deactivate Connect AI?
                </Fragment>
            </div>
        )
    };
    const AlertFooter = () => {
        return (
            <Fragment>
                <Fragment>
                    <button className="btn btn-secondary vcgradientbtn vcsecondary-btn"
                        onClick={() => AlertClose()}
                    >
                        <div className="vcsecondaryInner">Cancel</div>
                    </button>
                    <button className="btn btn-primary vcgradientbtn vcprimary-btn"
                        onClick={() => HandleButton(false)}
                    >Deactivate</button>
                </Fragment>
            </Fragment >
        )
    };
    return ReactDOM.render(
        <VGPopUp
            id={"Alert"}
            show={true}
            onHide={() => { AlertClose() }}
            title={AlertTitle()}
            dialogClassName="vgn-modal-alert vgn-modal-small modal-mb-half aiActiveModal"
            body={AlertBody()}
            footer={AlertFooter()}
            isRemoveTabModalIndex={false}
            isShowFooter={true}
            backdrop={true}
        />,
        document.getElementById("OpenDialog")
    );
};
export const TextMarketingEnableePopup = (callback = () => { }) => {
    const AlertClose = () => {
        unmountComponentAtNode(document.getElementById("OpenDialog"));
    };
    const HandleButton = (val) => {
        callback(val);
        AlertClose()
    }
    const AlertTitle = () => {
        return (
            <Fragment>
                <Fragment>Alert</Fragment>
            </Fragment>
        )
    }
    const AlertBody = () => {
        return (
            <div>
                <Fragment>
                    To activate Connect AI, please enable text marketing.
                </Fragment>
            </div>
        )
    }
    const AlertFooter = () => {
        return (
            <Fragment>
                <Fragment>
                    <button className="btn btn-secondary vcgradientbtn vcsecondary-btn"
                        onClick={() => AlertClose()}
                    >
                        <div className="vcsecondaryInner">Cancel</div>
                    </button>
                    <button className="btn btn-primary vcgradientbtn vcprimary-btn"
                        onClick={() => HandleButton(true)}
                    >Activate</button>
                </Fragment>
            </Fragment >
        )
    }


    return ReactDOM.render(
        <VGPopUp
            id={"Alert"}
            show={true}
            onHide={() => { AlertClose() }}
            title={AlertTitle()}
            dialogClassName="vgn-modal-alert vgn-modal-small modal-mb-half"
            body={AlertBody()}
            footer={AlertFooter()}
            isRemoveTabModalIndex={false}
            isShowFooter={true}
            backdrop={true}
        />,
        document.getElementById("OpenDialog")
    );
};
export const ConnectSettingWrap = ({ children }) => {
    const show = useSelector((state) => state.Settings.Activate === true);
    const { AIContent, SetAIContent } = useVagaroAIContext();
    const { ContentLoad } = AIContent.current;
    return (
        <div ref={(ele) => SetAIContent(AIContentEnum.ConnectSettingWrap, ele)} className={`vConnectMainWrap container-fluid p-0 ${show ? "bottom-show-footer" : ""} ${ContentLoad ? "" : "d-none"} `}>
            <div className="row g-0" >
                <div className='connect-settingWrap'>
                    <> {children} </>
                </div>
            </div>
        </div>
    );
};
const MobileRoutes = () => {
    const { Status: Settings_Status, Activate: show } = useSelector((state) => state.Settings);
    const { AIContent } = useVagaroAIContext();
    const navigate = useNavigate();
    useEffect(() => {
        if (show) {
            navigate('/setting/active');
        } else {
            navigate('/setting');
        }
        if (!window.SettingMobile_popStateEvent) {
            window.addEventListener('popstate', function (event) {
                console.log("popstate", event, AIContent);
                if (AIContent.current?.BackButton) AIContent.current.BackButton.click()
            });
            window.SettingMobile_popStateEvent = true
        }
    }, [show]);
    return (
        <div className={Settings_Status === StatusEnum.Idle ? 'connect-right-wrapper staincile' : 'connect-right-wrapper'}>
            <Routes>
                <Route path="workinghours"
                    element={<>
                        {/* <SettingHeader/> */}
                        <WorkingDays />
                        <AiLastModified />
                        {/* <SettingFooter /> */}
                    </>}
                />
                <Route path="active"
                    element={<>
                        {/* <SettingHeader /> */}
                        <HeaderBanner />
                        <AiSettingWrpper>
                            <HandlePause />
                            <HandleAISettings />
                        </AiSettingWrpper>
                        <DeActivate />
                        <AiLastModified />
                        {/* <SettingFooter /> */}
                    </>}
                />
                <Route path="*"
                    element={<>
                        {/* <SettingHeader /> */}
                        <HeaderBanner />
                        <SalesPage />
                        <AiLastModified />
                        {/* <SettingFooter /> */}
                    </>
                    }
                />
            </Routes>
        </div>

    )
};
export const ConnectMidWrap = ({ children }) => {
    const { isFromConnectApp, isAndroidIOSMobile, isIOSMobile } = useVagaroAIContext();
    if (isFromConnectApp && isAndroidIOSMobile) {
        const handleOutsideClickPopup = (event) => {
            if (event?.target?.className.includes("fade modal")) {
                unmountComponentAtNode(document.getElementById("OpenDialog"));
            }
        }
        document.addEventListener('click', handleOutsideClickPopup);
    }
    return (
        <div className='connet-mid-wrapper'>
            {isIOSMobile ?
                <> <MobileRoutes /> </>
                :
                <> {children} </>
            }
        </div>
    );
};
export const LeftComponentWrap = ({ children }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const backtoChat = (e) => {
        e.preventDefault();
        navigate('/');
        if (dispatch) {
            ThunkAPI(dispatch, ThunkApiEnum.AiSetting);
        }
    };
    return (
        <div className='connect-setting-left'>
            <div>
                <a alt="" className="leftside-toggle settingBack" aria-current="page" onClick={(e) => { backtoChat(e) }}>
                    <i className="fa-solid fa-chevron-left"></i>
                    <sapn className="setting-text">Settings</sapn>
                </a>
                <nav className="nav flex-column leftside-menu">
                    <a className="leftside-menu_options active"><i className="fa-solid fa-sparkles"></i>Connect AI</a>
                </nav>
            </div>
            <div className='ai-slider-wrapper'>
                {children}
            </div>
        </div>);
};
export const AiSettingWrpper = ({ children }) => {
    const show = useSelector((state) => state.Settings.Activate);
    const showWorkingDays = useSelector((state) => state.Settings.showWorkingDays);
    return show && !showWorkingDays ?
        <div className='mob-wiget2'>
            <div className='white-bg-box'>
                {children}
            </div>
        </div>
        :
        <></>
};
export const HeaderBanner = () => {
    const { isFromConnectApp } = useVagaroAIContext();
    const { Activate, showWorkingDays } = useSelector((state) => state.Settings);
    const ShowAIBanner = () => {
        if (isFromConnectApp) {
            if (Activate === false) {
                return true;
            }
            if (isAndroidIOSTabIpad() && showWorkingDays === false) {
                return true;
            }
        } else {
            return true;
        }
    }
    if (ShowAIBanner()) {
        return (
            <div className='connect-ai-banner'>
                <div className='ai-banner-left'>
                    <Status />
                    <Banner />
                </div>
                <div className='ai-banner-right'>
                    <div className='m-image-wrap'>
                        <img src="https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/connectAibannerimg.png" width="424" alt="" />
                    </div>
                </div>
            </div>
        );
    } else {
        return <></>
    }
};
export const HandlePause = () => {
    const paused = useSelector((state) => state.Settings.Pause);
    const UserAccess = useSelector((state) => state.Settings.UserAccess);
    const dispatch = useDispatch();
    const handlePauseChange = (event) => {
        if (UserAccess === false) {
            utils.ShowAlert("Restricted Access", "You have not been given access to this page. Contact your account admin or business owner to get access.", "Close")
        } else {
            dispatch(setPause(event.target.checked));
        }
    };
    return (
        <>
            <div className="div-with-switch margintop24">
                <div className="switch-checkboxhtml switch-mobile-right">
                    <input type='checkbox' id='demo' checked={paused} onChange={(e) => handlePauseChange(e)} />
                    <label for="demo" className="padding-top0">
                        <div className="access-level-title titletop-center">Pause Temporarily</div>
                        <div className="access-level-description">AI will be paused and will not reply to any customer chats until resumed.</div>
                    </label>
                </div>
            </div>
        </>
    );
};
export const HandleAISettings = () => {
    return (
        <div className='ipad-white-bg'>
            <div className='setting-page-heading margintop24 marginbottom24'>AI Settings</div>
            <AISettingsOptions />
            <AISettingsCustomHours />
        </div>
    )
};
export const WorkingDays = () => {
    const { selectedDay, showWorkingDays, CustomHours, CustomHoursValidation } = useSelector((state) => state.Settings);
    const { openTime, closeTime, dayID, activate } = CustomHours.find((day) => day.name === selectedDay) || CustomHoursEnum
    const index = CustomHours ? CustomHours.findIndex((day) => day.name === selectedDay) : 0;
    const deviceSelectedDay = CustomHours.filter((day) => day.name === selectedDay)
    const { CommonMethod } = useChatContext();
    const { handleNativeTimePicker } = utils;
    const dispatch = useDispatch();
    useEffect(() => {
        window.setMobileData = (res) => {
            const response = JSON.parse(res);
            const { actionResponse } = response;
            const { time, actionTag } = actionResponse;
            if (actionTag == 1) {
                CommonMethod.handleHoursValidate(openTime, time, index);
            } else {
                CommonMethod.handleHoursValidate(time, closeTime, index);
            }
            dispatch(updateCustomHoursFromResponse({
                time,
                actionTag
            }));
        };
    }, [dispatch, deviceSelectedDay]);
    const handleDayToggle = (event) => {
        dispatch(toggleCustomHours({ id: dayID, value: event.target.checked }));
        // CommonMethod.handleHoursValidate(openTime, closeTime, index);
        if (event.target.checked === false) {
            dispatch(ResetCustomHours({ id: index }));
        }
    };

    return (<>
        {selectedDay && showWorkingDays && (
            <div className='mobile-working-days'>
                <div className='mobile-working-days-wrap hour-card-box'>
                    <div className='m-working-row margin-left16'>
                        <div className='switch-checkboxhtml switch-mobile-right'>
                            <input
                                type='checkbox'
                                id='working1'
                                checked={activate}
                                onChange={(e) => handleDayToggle(e)}
                            />
                            <label htmlFor='working1' className='padding-top0'>
                                <div className='access-level-title titletop-center'>{openTime} to {closeTime}</div>
                            </label>
                        </div>
                    </div>
                    <div className='m-working-row padding-left16'>
                        <div className='days'>From</div>
                        <div
                            className='m-time'
                            onClick={() => {
                                if (activate) {
                                    handleNativeTimePicker(0, openTime === 'Start' ? '12:00 AM' : openTime)
                                }
                            }}
                        >
                            {openTime}
                        </div>
                        <div className='m-arrow-right'>
                            <i className='fa-solid fa-chevron-right'></i>
                        </div>
                    </div>
                    <div className='m-working-row padding-left16'>
                        <div className='days'>To</div>
                        <div className='m-time'
                            onClick={() => {
                                if (activate) {
                                    handleNativeTimePicker(1, closeTime === 'End' ? '12:00 AM' : closeTime)
                                }
                            }}
                        >
                            {closeTime}
                        </div>
                        <div className='m-arrow-right'>
                            <i className='fa-solid fa-chevron-right'></i>
                        </div>
                    </div>
                </div>
                <div style={{ display: CustomHoursValidation[index] ? "block" : 'none' }} className='mobileTimeValidation'>
                    <p>{CustomHoursValidation[index]}</p>
                </div>
            </div>
        )}
    </>)
};
export const DeActivate = () => {
    const Activate = useSelector((state) => state.Settings.Activate);
    const showWorkingDays = useSelector((state) => state.Settings.showWorkingDays);
    const { isFromConnectApp, AIContent, ModifyFeatureSubscription } = useVagaroAIContext();
    const { RightWrapper } = AIContent.current;
    const UserAccess = useSelector((state) => state.Settings.UserAccess);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const HandleDeactiveButton = () => {
        dispatch(setActivate(false));
        if (isFromConnectApp) {
            navigate("/setting")
        }
        if (dispatch) {
            ThunkAPI(dispatch, ThunkApiEnum.UpdateSetting)
                .then(() => {
                    utils.SuccessBanner('Connect AI Deactivated');
                    if (AIContent.current?.RightWrapper) {
                        AIContent.current.RightWrapper.scrollTop = 0;
                    }
                })
                .catch(() => {
                });
        }

    }
    if (Activate && !isAndroidIOSTabIpad() && showWorkingDays === false) {
        return (
            <div className='deactivate-wrap'>
                <button className="btn deactivate-btn" onClick={() => UserAccess === false ? utils.ShowAlert("Restricted Access", "You have not been given access to this page. Contact your account admin or business owner to get access.", "Close") : ModifyFeatureSubscription === false ? utils.FailBanner('You do not have access to modify this page.') : DeactivePopup(HandleDeactiveButton)} onFocus={(e) => e.currentTarget.blur()}>Deactivate</button>
            </div>
        );
    } else {
        return <></>;
    }
};
export const AiLastModified = () => {
    const { ReactV2BId, ReactV2UId, isFromConnectApp } = useVagaroAIContext();
    const dispatch = useDispatch();
    const GetAiSettingHistory = async () => {
        const accesstoken = await useGenerateToken(dispatch);
        await AsyncAjaxGet(
            `merchants/${ReactV2BId}/settinghistory/getlastdate?UserId=${ReactV2UId}&ModuleId=2010`,
            accesstoken,
            function onSuccess(data) {
                try {
                    if (data != null && data.data != null && data.data.data != null) {
                        if (data.data.data != null && data.data.data.modified_by_name != null && (data.data.data.modified_date != null || !utils.isNullEmptyOrWhiteSpace(data.data.data.modified_date))) {
                            var name = data.data.data.modified_by_name.split(' ');
                            for (var i = 0; i < name.length; i++) {
                                name[i] = name[i].charAt(0).toUpperCase() + name[i].slice(1);
                            }
                            var ModifiedByName = name.join(' ');
                            document.getElementById('dvLastModified2010').innerHTML = "Last Modified: " + ModifiedByName + " - " + data.data.data.modifiedDate;
                        }
                    }

                } catch (error) {
                    utils.appInsightTrack(
                        ReactV2BId,
                        ReactV2UId,
                        "Date Format",
                        "",
                        error
                    );
                }
            },
            function OnError(data) {
                utils.appInsightTrack(
                    ReactV2BId,
                    ReactV2UId,
                    "Date Format",
                    "",
                    data
                );
            }, false
        );
    }
    useEffect(() => {
        GetAiSettingHistory();
    }, []);

    const OnClickSettingHistoryFunction = () => {
        if (isFromConnectApp) {
            utils.CallBackGivenToMobile(MobileCallBackAction.AiSettingHistory, { "moduleId": AiSettingModuleId })
        } else {
            var jsonData = JSON.stringify({ ReactBusinessId: ReactV2BId, LoginUserIdHash: ReactV2UId, type: 2010, ShowPopup: true }).replace(/\\\"/g, '"').replace(/"/g, "\\\"");
            window.parent.postMessage('{"eventType": "AiLastModifiedDetails","Data": "' + jsonData + '"}', '*');
        }
    }

    return (
        <>
            <a href={false} id="dvLastModified2010" className='lastModified' onClick={() => OnClickSettingHistoryFunction()}></a>
        </>
    )
}
export const SettingHeader = () => {
    const { showWorkingDays, selectedDay } = useSelector((state) => state.Settings);
    const { isAndroidIOSMobile, isFromConnectApp, SetAIContent } = useVagaroAIContext();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const HandleBackClick = () => {
        if (isFromConnectApp && showWorkingDays) {
            navigate("/setting/active")
            dispatch(toggleWorkingDaysVisibility());
        } else {
            if (showWorkingDays) {
                dispatch(toggleWorkingDaysVisibility());
            } else {
                utils.MobileDatePicker(`{"message": "","messageType": 0,"screenTitle": "","screenType": 0,"navType": 0,"action": "92" }`);
                navigate("/")
            }
        }
        if (isAndroidIOSMobile) {
            setTimeout(() => {
                const messageElement = document.getElementsByClassName('mobile-working-days-wrap');
                if (messageElement.length > 0) {
                    messageElement[0].scrollIntoView({ block: 'end', behavior: 'smooth' });
                }
            }, 100);
        }
    }
    return (
        <div className={`messagesArea-header show-div-app connectAiSettingPageHeader ${isAndroidIOSTabIpad() ? '' : 'hide-div-desktop'} ${!isAndroidIOSMobile && selectedDay && showWorkingDays && isAndroidIOSTabIpad() ? "custom-hours-details" : ""}`}>
            <div className='messagesArea-header_nameArea'>
                <a ref={(ele) => SetAIContent(AIContentEnum.BackButton, ele)} href={false} className="messagesArea-header_back" onClick={() => HandleBackClick()}>
                    <i className="fa-solid fa-angle-left" aria-hidden="true"></i>
                </a>
                <div className='messagesArea-header_name'>{showWorkingDays === false ? "Connect AI" : `${selectedDay} ${isFromConnectApp && isAndroidIOSTabIpad() ? "" : "Hours"} `}
                    {showWorkingDays === false && !isAndroidIOSTabIpad() && <Status />}
                </div>
                <div className="messagesArea-header_moreVertical"></div>
            </div>
        </div>
    );
};
export const Banner = () => {
    const { TextMarketingEnable, NumberStatus } = useSelector((state) => state.TextMarketing);
    const { Activate: show, UserAccess, currencySymbol, aiConnectPrice } = useSelector((state) => state.Settings);
    const { isAndroidIOSMobile, ModifyFeatureSubscription } = useVagaroAIContext();
    const dispatch = useDispatch();
    const deactivateButtonRef = useRef(null);

    useEffect(() => {
        if (show && deactivateButtonRef.current) {
            deactivateButtonRef.current.blur();
        }
    }, [show]);

    const HandleActiveAISettings = () => {
        if (UserAccess === false) {
            utils.ShowAlert("Restricted Access", "You have not been given access to this page. Contact your account admin or business owner to get access.", "Close")
            return;
        };
        if (ModifyFeatureSubscription === false) {
            utils.FailBanner('You do not have access to modify this page.');
            return;
        }
        if (TextMarketingEnable === null || TextMarketingEnable !== true) {
            TextMarketingEnableePopup((isConfirmed) => {
                if (isConfirmed) {
                    HandleTextMarketingEnableeButton();
                }
            });
            return;
        } else {
            updateSetting(true);
        };
    };

    const updateSetting = (val) => {
        dispatch(setActivate(val));
        if (UserAccess && val) {
            if (dispatch) {
                ThunkAPI(dispatch, ThunkApiEnum.UpdateSetting)
                    .then(() => {
                        utils.SuccessBanner('Connect AI Activated');
                    })
                    .catch(() => {
                    });
            };
        };
    };

    const HandleDeactiveButton = () => {
        dispatch(setActivate(false));
        if (dispatch) {
            ThunkAPI(dispatch, ThunkApiEnum.UpdateSetting)
                .then(() => {
                    utils.SuccessBanner('Connect AI Deactivated');
                })
                .catch(() => {
                });
        }
    };

    const OpenTextMessagingPlanPopup = (isEnable2WaySMS) => {
        let isIpad = /Macintosh/i.test(navigator.userAgent) && navigator.maxTouchPoints && navigator.maxTouchPoints > 1;
        let isIpadold = /iPad/i.test(navigator.userAgent);
        let isandroid = /VagaroAndroidPhone/i.test(navigator.userAgent);
        let isandroidTab = /VagaroAndroidTablet/i.test(navigator.userAgent);

        if (isAndroidIOSMobile || isIpad || isIpadold || isandroid || isandroidTab) {
            utils.CallBackGivenToMobile(MobileCallBackAction.OpenTextMessaging, true)
        } else {
            var jsonData = JSON.stringify({ isEnable2WaySMS: isEnable2WaySMS }).replace(/\\\"/g, '"').replace(/"/g, "\\\"");
            window.parent.postMessage('{"eventType": "OpenTextMessagingPlanActivePopup","Data": "' + jsonData + '"}', '*');
        }
    }

    const HandleTextMarketingEnableeButton = () => {
        OpenTextMessagingPlanPopup(false);
        // updateSetting(val);
    }

    return (
        <div className='ai-setting-banner'>
            <h1 className='banner-heading'>Connect AI</h1>
            <div className='banner-discription-text'>Enable AI responses to incoming customer messages.</div>
            {(isAndroidIOSMobile === false || isAndroidIOSTabIpad()) &&
                <div className=''>
                    {show === false ?
                        <button className="btn vcconnect-primary-btn vcgradientbtn vcprimary-btn" onClick={() => HandleActiveAISettings()}>Activate - {currencySymbol}{aiConnectPrice}/mo</button>
                        :
                        <button ref={deactivateButtonRef} className="btn vcconnect-secondary-btn" onClick={() => UserAccess === false ? utils.ShowAlert("Restricted Access", "You have not been given access to this page. Contact your account admin or business owner to get access.", "Close") : ModifyFeatureSubscription === false ? utils.FailBanner('You do not have access to modify this page.') : DeactivePopup(HandleDeactiveButton)} onFocus={(e) => e.currentTarget.blur()}>Deactivate</button>}
                </div>
            }
            {NumberStatus === 1 && show &&
                <div className='aiImportantNote'>Note: AI will engage once your business number has been approved.</div>
            }
        </div>
    )
};
export const AISlideContent = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const handleTryAIClick = (e) => {
        e.preventDefault();
        navigate('/setting');
        if (dispatch) {
            ThunkAPI(dispatch, ThunkApiEnum.AiSetting);
        }
    };
    return (
        <div className='ai-slider-banner'>
            <img src="https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/ai-slider-icon.png" width="85" alt="" />
            <h3 className='ai-slider-heading'>Try AI for Connect</h3>
            <p className='ai-slider-description'>Let AI answer all your messages so you can focus on your business.</p>
            <a href={false} className='ai-try-button' onClick={handleTryAIClick}>Try AI</a>
        </div>
    );
};
export const UpgradeSlideContent = (props) => {
    const { ReactBusinessId, isStenciledForSMS, isAndroidIOSMobile } = props

    const OpenTextMessagingPlanPopup = (isEnable2WaySMS) => {
        let isIpad = /Macintosh/i.test(navigator.userAgent) && navigator.maxTouchPoints && navigator.maxTouchPoints > 1;
        let isIpadold = /iPad/i.test(navigator.userAgent);
        let isandroid = /VagaroAndroidPhone/i.test(navigator.userAgent);
        let isandroidTab = /VagaroAndroidTablet/i.test(navigator.userAgent);

        if (isAndroidIOSMobile || isIpad || isIpadold || isandroid || isandroidTab) {
            utils.CallBackGivenToMobile(MobileCallBackAction.OpenTextMessaging, "")
        } else {
            var jsonData = JSON.stringify({ isEnable2WaySMS: isEnable2WaySMS }).replace(/\\\"/g, '"').replace(/"/g, "\\\"");
            window.parent.postMessage('{"eventType": "OpenTextMessagingPlanPopup","Data": "' + jsonData + '"}', '*');
        }
    }
    if (ReactBusinessId !== 226) {
        return (
            <>
                <div className={`upgrade-banner ${isStenciledForSMS ? "stenciled" : ""}`}>
                    <img src="https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/connect_in-app_upgrade2.png" alt="" width="60" />
                    <h3 className='ai-slider-heading'>Upgrade to SMS Texts</h3>
                    <p className='ai-slider-description'>Click here and activate your text plan and ensure your customers receive your messages anytime, anywhere.</p>
                    <a href={false} className="ai-try-button" onClick={() => OpenTextMessagingPlanPopup(false)}>Upgrade Now</a>
                </div>
            </>
        )
    }
};
export const SalesPage = () => {
    const show = useSelector((state) => state.Settings.Activate);
    return show ? <></> : <div className="salespage-wrap">Sales Page</div>;
};
export const AISettingsOptions = () => {
    const { UserAccess, Pause, AISettings } = useSelector((state) => state.Settings);
    const { isFromConnectApp } = useVagaroAIContext();
    const dispatch = useDispatch();

    const handleOptionChange = (val) => {
        if (UserAccess === false) {
            utils.ShowAlert("Restricted Access", "You have not been given access to this page. Contact your account admin or business owner to get access.", "Close");
        } else {
            dispatch(SetSettingControl(val));
            if (val === "replyDuringCustomHours" && !isFromConnectApp) {
                setTimeout(() => {
                    const messageElement = document.getElementsByClassName('bussiness-hour-wrapper');
                    if (messageElement.length > 0) {
                        messageElement[0].scrollIntoView({ block: 'nearest', behavior: 'smooth' });
                    }
                }, 100);
            }
        }
    };

    return (
        <div className='ai-box-wrapper'>
            <div className='ai-setting-box'>
                <div className='customredio-box-singal'>
                    <input
                        type="radio"
                        name="aiSetting"
                        id="demo2"
                        value={1}  // Corresponds to replyToAll
                        checked={AISettings === 1}  // Check if AISettings is 1
                        onClick={() => handleOptionChange("replyToAll")}
                        disabled={Pause}
                    />
                    <label htmlFor="demo2">
                        <div className='ai-boxt-heading'>Reply to All Chats</div>
                        <div className='ai-boxt-description'>AI will respond to all incoming customer chats. You can always take over an AI chat if you wish.</div>
                    </label>
                </div>
            </div>

            <div className='ai-setting-box'>
                <div className='customredio-box-singal'>
                    <input
                        type="radio"
                        name="aiSetting"
                        id="demo3"
                        value={2}  // Corresponds to replyDuringOffHours
                        checked={AISettings === 2}  // Check if AISettings is 2
                        onClick={() => handleOptionChange("replyDuringOffHours")}
                        disabled={Pause}
                    />
                    <label htmlFor="demo3">
                        <div className='ai-boxt-heading'>Reply During Business’ Off Hours</div>
                        <div className='ai-boxt-description'>AI will only reply to customer chats outside of business hours.</div>
                    </label>
                </div>
            </div>

            <div className='ai-setting-box'>
                <div className='customredio-box-singal'>
                    <input
                        type="radio"
                        name="aiSetting"
                        id="demo4"
                        value={3}  // Corresponds to replyDuringCustomHours
                        checked={AISettings === 3}  // Check if AISettings is 3
                        onClick={() => handleOptionChange("replyDuringCustomHours")}
                        disabled={Pause}
                    />
                    <label htmlFor="demo4">
                        <div className='ai-boxt-heading'>Reply During Custom Hours</div>
                        <div className='ai-boxt-description'>Set custom hours that AI will reply to customer chats.</div>
                    </label>
                </div>
            </div>
        </div>
    );
};
export const DayRow = ({ day, name, disabled, activate, index, openTime, closeTime, HandleValidate, validationMessage }) => {
    const { Pause } = useSelector((state) => state.Settings);
    const UserAccess = useSelector((state) => state.Settings.UserAccess);
    const dispatch = useDispatch();

    const handleDayToggle = (event) => {
        if (UserAccess === false) {
            utils.ShowAlert("Restricted Access", "You have not been given access to this page. Contact your account admin or business owner to get access.", "Close")
        } else {
            dispatch(toggleCustomHours({ id: day, value: event.target.checked }));
        }
        if (event.target.checked === false) {
            dispatch(ResetCustomHours({ id: index }));
        }
    };



    const handleStartTime = (value) => {
        dispatch(handleCustomHours({ id: index, isOpenTime: true, value: value }));
        HandleValidate({ fromTime: value, toTime: closeTime }, index)

    }

    const handleEndTime = (value) => {
        dispatch(handleCustomHours({ id: index, closeTime: true, value: value }));
        HandleValidate({ fromTime: openTime, toTime: value }, index)

    }

    const copyToAllDays = (sourceDayId) => {
        if (UserAccess === false) {
            utils.ShowAlert("Restricted Access", "You have not been given access to this page. Contact your account admin or business owner to get access.", "Close");
        } else {
            if (!Pause) {
                const isValid = HandleValidate({ fromTime: openTime, toTime: closeTime }, index);
                if (isValid) {
                    dispatch(HandleCopyToAll({ id: index }));
                }
            }
        }
    };


    // const dayData = workingHours.find(d => d.dayId === day);
    return (
        <>
            <div className={`bs-table-row `}>
                <div className="bs-row-content">
                    <div className="bs-column-content-small first">
                        <div className="switch-checkboxhtml">
                            <input id={`${day}Update`} type="checkbox"
                                // checked={dayStates[day]}
                                checked={activate}
                                onChange={(e) => handleDayToggle(e)}
                                disabled={disabled}
                            />
                            <label htmlFor={`${day}Update`}>
                                <span className="day">{name}</span>
                            </label>
                        </div>
                    </div>
                    {/* {dayData && dayData.slots.map(slot => (
                            <React.Fragment key={slot.id}> */}

                    <div className="bs-column-content-small">
                        <div className="bs-field-border">
                            <div className="timepickerbox">
                                <TimePicker
                                    className={`${Pause ? 'hours-row-active' : ''}`}
                                    ControlID={`${name}OpenTime`}
                                    placeholder="Open"
                                    defaultValue={openTime}
                                    isDisableTime={!activate}
                                    onChange={value => {
                                        handleStartTime(roundToNearestFive(value))
                                    }}

                                />
                            </div>
                        </div>
                    </div>
                    <div className="desh-text">-</div>
                    <div className="bs-column-content-small">
                        <div className="bs-field-border">
                            <div className="timepickerbox">
                                <TimePicker
                                    className={`${Pause ? 'hours-row-active' : ''}`}
                                    ControlID={`${name}CloseTime`}
                                    placeholder="Close"
                                    defaultValue={closeTime}
                                    isDisableTime={!activate}
                                    onChange={value => {
                                        handleEndTime(roundToNearestFive(value))
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    {/* </React.Fragment>
                        ))} */}

                    {activate && !Pause ?
                        <div className="bs-delete-row-icon bs-copy-to-all" onClick={() => copyToAllDays(day)}>
                            <div className="bs-delete-row-icon bs-copy-icon"></div>
                            <div className="bs-copy-to-all">Copy to all</div>
                        </div>
                        :
                        <div className="bs-delete-row-icon bs-copy-to-all">
                            {/* <div className="bs-delete-row-icon bs-copy-icon"></div> */}
                            {/* <div className="bs-copy-to-all">Copy to all</div> */}
                        </div>
                    }
                </div>
                <div id={`div${name}Validation`} style={{ display: validationMessage ? 'block' : 'none' }}>
                    <span className="requiredErrorText" id={`${name}Validation`}>
                        {validationMessage || ''}
                    </span>
                </div>
            </div>
        </>
    )

};
export const AISettingsCustomHours = () => {
    const { isFromConnectApp, isAndroidIOSMobile } = useVagaroAIContext();
    const { AddCommonMethod } = useChatContext();
    const { AISettings } = useSelector((state) => state.Settings);
    const { CustomHours, Pause } = useSelector((state) => state.Settings);
    const validationMessage = useSelector((state) => state.Settings.CustomHoursValidation);

    const AISettingChages = useRef(true);
    const navigate = useNavigate();

    const dispatch = useDispatch();
    const handleDayClick = (day) => {
        dispatch(toggleDay(day));
    };
    const handleToggleVisibility = () => {
        if (isFromConnectApp) {
            navigate('/setting/workinghours');
        }
        dispatch(toggleWorkingDaysVisibility());
    };

    const HandleValidate = useCallback(
        (slot, index, AllowEmpty = false) => {
            let isValid = true;
            let message = ''
            if (!utils.isValidTimeFormat(slot.fromTime)) {
                if (!AllowEmpty || slot.fromTime === "Start") {
                    isValid = false;
                    message = "Please enter a valid start time";

                }
            }
            if (isValid && !utils.isValidTimeFormat(slot.toTime)) {
                if (!AllowEmpty || slot.toTime === "End") {
                    isValid = false;
                    message = "Please enter a valid end time.";
                }
            }
            if (isValid) {
                try {
                    const fromTime24 = utils.convertTo24HourFormatForValidation(slot.fromTime);
                    const toTime24 = utils.convertTo24HourFormatForValidation(slot.toTime);

                    if (slot.fromTime === "Start" || slot.toTime === "End") {
                        if (!AllowEmpty) {
                            isValid = false;
                            message = "Start time or end time is missing.";
                        }
                    } else if (toTime24 <= fromTime24) {
                        isValid = false;
                        message = "End time must be greater than start time.";
                    } else if (utils.checkIsAfterEndOfDay(slot.fromTime, slot.toTime)) {
                        isValid = false;
                        message = "Time slot cannot exceed the end of the day.";
                    }

                } catch (error) {
                    isValid = false;
                    message = error.message
                }
            }
            dispatch(SetHoursValidation({ id: index, value: message }));
            return isValid
        },
        [],
    )


    const trigarValidation = (checkEmpty) => {
        CustomHours.map((day, index) => HandleValidate({ fromTime: day.openTime, toTime: day.closeTime }, index, checkEmpty))
    }
    const Content = () => {
        switch (AISettings) {
            case AISettingEnum.replyDuringCustomHours:
                if (isAndroidIOSMobile || isFromConnectApp) {
                    return (
                        <>
                            <div className={Pause ? 'mobile-working-days-wrap show-div-app hide-div-desktop ipad-app-show daysBlockDisabled ' : 'mobile-working-days-wrap show-div-app hide-div-desktop ipad-app-show'} onClick={handleToggleVisibility}>
                                {CustomHours?.map((day) => (
                                    <div
                                        key={day}
                                        className={`m-working-row${day.activate ? ' active' : ''}`}
                                        onClick={() => !Pause && handleDayClick(day.name)}
                                    >
                                        <div className={`m-dot ${day.activate ? 'active' : ''}`}></div>
                                        <div className='days'>{day.name}</div>
                                        <div className='m-time'>{day.openTime} - {day.closeTime}</div>
                                        <div className='m-arrow-right'>
                                            <i className='fa-solid fa-chevron-right'></i>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </>
                    );
                } else {
                    return (
                        <>
                            <div className='bussiness-hour-wrapper mob-app-hide ipad-app-hide'>
                                <div className="busHours" >
                                    <div className="bus-working-hoursList">
                                        <div className="bus-header-row">
                                            <div className="bus-row-content">
                                                <div className="bs-column-content-small first">
                                                    <div className="bs-table-header-text">
                                                        Day of the week
                                                    </div>
                                                </div>
                                                <div className="bs-column-content-small">
                                                    <div className="bs-table-header-text">
                                                        Start
                                                    </div>
                                                </div>
                                                <div className='desh-text'></div>
                                                <div className="bs-column-content-small">
                                                    <div className="bus-table-header-text">
                                                        End
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {CustomHours.map((day, index) => (
                                            <DayRow key={day.dayID} day={day.dayID} index={index} name={day.name} activate={day.activate} openTime={day.openTime} closeTime={day.closeTime} disabled={Pause} HandleValidate={HandleValidate} trigarValidation={trigarValidation} validationMessage={validationMessage[index]} />
                                        ))}
                                    </div>
                                </div>
                            </div>
                            {/* <a href="" className='lastModified'>Last Modified: Shahrukh Divan - Jun 27, 2024 - 7:36 AM</a> */}
                        </>
                    )
                }
            default:
                return <></>
        }
    }
    useEffect(() => {
        if (AISettings === AISettingEnum.replyDuringCustomHours && AISettingChages.current) {
            if (isFromConnectApp) {
                trigarValidation(true);
            }
            AISettingChages.current = false;
        } else {
            AISettingChages.current = true;
        }
        AddCommonMethod("handleCustomHoursValidate", () => {
            trigarValidation(false);
            return CustomHours.filter(hours => hours.activate === true && (hours.openTime === "Start" || hours.closeTime === "End")).length > 0;
        });
        AddCommonMethod("handleHoursValidate", (openTime, closeTime, index) => {
            HandleValidate({ fromTime: openTime, toTime: closeTime }, index);
        });
    }, [AISettings, CustomHours, AddCommonMethod, HandleValidate, trigarValidation]);

    return (
        <Content />
    )
};
export const SettingFooter = () => {
    const { UserAccess, selectedDay, showWorkingDays, CustomHoursValidation, Pause, CustomHours, currencySymbol, aiConnectPrice } = useSelector((state) => state.Settings);
    const { isFromConnectApp, isAndroidIOSMobile, setShowLoader, ModifyFeatureSubscription, AIContent } = useVagaroAIContext();
    const replyDuringCustomHours = useSelector((state) => state.Settings.AISettings);
    const index = CustomHours.findIndex((day) => day.name === selectedDay)
    const allDeactivated = CustomHours.every(day => day.activate === false);
    const show = useSelector((state) => state.Settings.Activate === true);
    const hasValidationMessages = Object.values(CustomHoursValidation).some(msg => msg !== "");
    const { TextMarketingEnable } = useSelector((state) => state.TextMarketing);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const onChange = () => {
        // if(isFromConnectApp){
        //     navigate('active')
        // }
        // dispatch(setActivate(val));
        if (UserAccess === false) {
            utils.ShowAlert("Restricted Access", "You have not been given access to this page. Contact your account admin or business owner to get access.", "Close")
            return;
        }
        if (ModifyFeatureSubscription === false) {
            utils.FailBanner('You do not have access to modify this page.');
            return;
        }
        if (TextMarketingEnable === null || TextMarketingEnable !== true) {
            TextMarketingEnableePopup((isConfirmed) => {
                if (isConfirmed) {
                    HandleTextMarketingEnableeButton();
                }
            });
            return;
        } else {
            updateSetting(true);
        };
    }

    const HandleTextMarketingEnableeButton = () => {
        OpenTextMessagingPlanPopup(false);
        // updateSetting(val);
    }

    const updateSetting = (val) => {
        dispatch(setActivate(val));
        if (UserAccess && val) {
            if (dispatch) {
                ThunkAPI(dispatch, ThunkApiEnum.UpdateSetting)
                    .then(() => {
                        utils.SuccessBanner('Connect AI Activated');
                    })
                    .catch(() => {
                    });
            };
        };
    }

    const OpenTextMessagingPlanPopup = (isEnable2WaySMS) => {
        let isIpad = /Macintosh/i.test(navigator.userAgent) && navigator.maxTouchPoints && navigator.maxTouchPoints > 1;
        let isIpadold = /iPad/i.test(navigator.userAgent);
        let isandroid = /VagaroAndroidPhone/i.test(navigator.userAgent);
        let isandroidTab = /VagaroAndroidTablet/i.test(navigator.userAgent);

        if (isAndroidIOSMobile || isIpad || isIpadold || isandroid || isandroidTab) {
            utils.CallBackGivenToMobile(MobileCallBackAction.OpenTextMessaging, true)
        } else {
            var jsonData = JSON.stringify({ isEnable2WaySMS: isEnable2WaySMS }).replace(/\\\"/g, '"').replace(/"/g, "\\\"");
            window.parent.postMessage('{"eventType": "OpenTextMessagingPlanActivePopup","Data": "' + jsonData + '"}', '*');
        }
    }

    const handleToggleVisibility = () => {
        const isValid = trigarValidation(true)
        if (isFromConnectApp && showWorkingDays == false) {
            utils.MobileDatePicker(`{"message": "","messageType": 0,"screenTitle": "","screenType": 0,"navType": 0,"action": "92" }`);
        }
        if (selectedDay && showWorkingDays && CustomHoursValidation[index].length <= 0 && isValid) {
            if (isFromConnectApp) {
                navigate('/setting/active');
            }
            // if(!isFromConnectApp){
            //     navigate('/');
            // }
            dispatch(toggleWorkingDays());
            if (isAndroidIOSMobile) {
                setTimeout(() => {
                    const messageElement = document.getElementsByClassName('mobile-working-days-wrap');
                    if (messageElement.length > 0) {
                        messageElement[0].scrollIntoView({ block: 'end', behavior: 'smooth' });
                    }
                }, 100);
            }
        }
    };
    const ShowAlert = (val) => {
        const AlertClose = () => {
            unmountComponentAtNode(document.getElementById("OpenDialog"));
        };
        const AlertTitle = () => {
            return (
                <Fragment>Require Custom Hours </Fragment>
            )
        }
        const AlertBody = () => {
            return (
                <div>
                    Custom hours must be activated for at least one day to enable AI.
                </div>
            )
        }
        const AlertFooter = () => {
            return (
                <Fragment>
                    <button className="btn btn-primary vcgradientbtn vcprimary-btn minWidthForOkButton"
                        onClick={() => AlertClose()}
                    >OK</button>
                </Fragment >
            )
        }
        return ReactDOM.render(
            <VGPopUp
                id={"Alert"}
                show={true}
                onHide={() => { AlertClose() }}
                title={AlertTitle()}
                dialogClassName="vgn-modal-alert vgn-modal-small modal-mb-half"
                body={AlertBody()}
                footer={AlertFooter()}
                isRemoveTabModalIndex={false}
                isShowFooter={true}
                backdrop={true}
            />,
            document.getElementById("OpenDialog")
        );
    }

    const HandleValidate = useCallback(
        (slot, index, AllowEmpty = false) => {
            let isValid = true;
            let message = ''
            if (!utils.isValidTimeFormat(slot.fromTime)) {
                if (!AllowEmpty || slot.fromTime === "Start") {
                    isValid = false;
                    message = "Please enter a valid start time";

                }
            }
            if (isValid && !utils.isValidTimeFormat(slot.toTime)) {
                if (!AllowEmpty || slot.toTime === "End") {
                    isValid = false;
                    message = "Please enter a valid end time.";
                }
            }
            if (isValid) {
                try {
                    const fromTime24 = utils.convertTo24HourFormatForValidation(slot.fromTime);
                    const toTime24 = utils.convertTo24HourFormatForValidation(slot.toTime);

                    if (slot.fromTime === "Start" || slot.toTime === "End") {
                        if (!AllowEmpty) {
                            isValid = false;
                            message = "Start time or end time is missing.";
                        }
                    } else if (toTime24 <= fromTime24) {
                        isValid = false;
                        message = "End time must be greater than start time.";
                    } else if (utils.checkIsAfterEndOfDay(slot.fromTime, slot.toTime)) {
                        isValid = false;
                        message = "Time slot cannot exceed the end of the day.";
                    }
                } catch (error) {
                    isValid = false;
                    message = error.message
                }
            }
            dispatch(SetHoursValidation({ id: index, value: message }));
            return isValid
        },
        [],
    )


    const trigarValidation = (checkEmpty) => {
        let allValid = true;
        CustomHours.forEach((day, index) => {
            if (day.activate) {
                const isValid = HandleValidate({ fromTime: day.openTime, toTime: day.closeTime }, index, checkEmpty, day.activate);
                if (!isValid) {
                    allValid = false;
                }
            }
        });
        return allValid;
    };

    const saveSettings = () => {
        setShowLoader(true);
        ThunkAPI(dispatch, ThunkApiEnum.UpdateSetting)
            .then(() => {
                setTimeout(() => {
                    setShowLoader(false);
                }, 500);
                utils.SuccessBanner('AI Setting has been saved successfully.');
            })
            .catch(() => {
                setTimeout(() => {
                    setShowLoader(false);
                }, 500);
                utils.ErrorBanner('Failed to save settings.');
            });
    };

    const handleSave = async () => {
        if (ModifyFeatureSubscription === false) {
            utils.FailBanner('You do not have access to modify this page.');
            return <></>;
        }
        const isValid = trigarValidation(true);
        if (replyDuringCustomHours === 3 && allDeactivated && !Pause) {
            ShowAlert()
        } else {
            if (UserAccess === false) {
                utils.ShowAlert("Restricted Access", "You have not been given access to this page. Contact your account admin or business owner to get access.", "Close")
            } else {
                if (Pause) {
                    saveSettings();
                } else if (isValid || replyDuringCustomHours !== 3) {
                    saveSettings();
                } else if (hasValidationMessages && isFromConnectApp) {
                    utils.WarningBanner('Please enter a valid start & end time');
                }
            }
        }
    };
    const handleOnTabCancel = () => {
        // dispatch(toggleWorkingDaysVisibility());
        if (isFromConnectApp) {
            if (AIContent.current?.BackButton) AIContent.current.BackButton.click()
        }
    }
    const handleCancel = () => {
        utils.MobileDatePicker(`{"message": "","messageType": 0,"screenTitle": "","screenType": 0,"navType": 0,"action": "92" }`);
        navigate("/")
    }
    if (show) {
        if (isFromConnectApp && !isAndroidIOSMobile) {
            return (<>
                {!isAndroidIOSMobile && showWorkingDays == false && <div className='settingfooter'>
                    <button className="btn vcconnect-secondary-btn vcgradient-border-btn  margin-bottom8" onClick={() => { navigate('/'); handleToggleVisibility() }}><span>Cancel</span></button>
                    <button className="btn vcconnect-primary-btn vcgradientbtn vcprimary-btn" onClick={() => handleSave()}>Save</button>
                </div>}
                <div className={selectedDay && showWorkingDays && !isAndroidIOSTabIpad() ? 'settingfooter' : "desktop-hide"}> <button className="btn vcconnect-primary-btn vcgradientbtn vcprimary-btn" onClick={() => handleToggleVisibility()}>Update</button></div>
                {!isAndroidIOSMobile && selectedDay && showWorkingDays && isAndroidIOSTabIpad() && (
                    <div className='settingfooter'>
                        <><button className="btn vcconnect-secondary-btn vcgradient-border-btn  margin-bottom8" onClick={() => handleOnTabCancel()} ><span>Cancel</span></button>
                            <button className="btn vcconnect-primary-btn vcgradientbtn vcprimary-btn" onClick={() => handleToggleVisibility()}>Save</button></>
                    </div>
                )}
            </>)
        } else {
            return (
                <>
                    {selectedDay && showWorkingDays ? <></> :
                        <div className='settingfooter'>
                            <button onClick={() => handleCancel()} className="btn vcconnect-secondary-btn vcgradient-border-btn  margin-bottom8"><span>Cancel</span></button>
                            <button className="btn vcconnect-primary-btn vcgradientbtn vcprimary-btn" onClick={() => handleSave()}>Save</button>
                        </div>
                    }
                    <div className={isAndroidIOSMobile && selectedDay && showWorkingDays && !isAndroidIOSTabIpad() ? 'settingfooter' : "desktop-hide"}> <button className="btn vcconnect-primary-btn vcgradientbtn vcprimary-btn" onClick={() => handleToggleVisibility()}>Update</button></div>
                    {isAndroidIOSMobile && selectedDay && showWorkingDays && isAndroidIOSTabIpad() && (
                        <div className='settingfooter'>
                            <><button className="btn vcconnect-secondary-btn vcgradient-border-btn  margin-bottom8" onClick={() => handleOnTabCancel()} ><span>Cancel</span></button>
                                <button className="btn vcconnect-primary-btn vcgradientbtn vcprimary-btn" onClick={() => handleToggleVisibility()}>Save</button></>
                        </div>
                    )}


                    <div className='settingfooter desktop-hide'> <button onClick={() => onChange()} className="btn vcconnect-primary-btn vcgradientbtn vcprimary-btn">Activate - {currencySymbol}{aiConnectPrice}/mo</button></div>
                </>
            )
        }
    } else {
        if (isFromConnectApp) {
            if (!isAndroidIOSTabIpad()) {
                return <div className='settingfooter'> <button className="btn vcconnect-primary-btn vcgradientbtn vcprimary-btn" onClick={() => onChange(true)}>Activate - {currencySymbol}{aiConnectPrice}/mo</button> </div>
            }
            return <></>
        } else {
            return <></>
        }
    }
};
export const Loader = () => {
    const { showLoader, AIContent, SetAIContent } = useVagaroAIContext();
    const { Status } = useSelector((state) => state.Settings);
    const { ContentLoad, ConnectSettingWrap } = AIContent.current;
    const show = useCallback(
        () => {
            if (showLoader || Status === StatusEnum.Loading) {
                return { display: "block" };
            } else {
                return { display: "none" };
            }
        },
        [showLoader, Status],
    );

    useEffect(() => {
        if (Status !== StatusEnum.Idle && Status !== StatusEnum.Loading && ContentLoad === false) {
            SetAIContent(AIContentEnum.ContentLoad, true);
            if (ConnectSettingWrap?.classList) {
                ConnectSettingWrap.classList.remove("d-none");
            };
        }
    }, [Status, ContentLoad, SetAIContent, ConnectSettingWrap]);

    return (
        <div ref={(ele) => SetAIContent(AIContentEnum.Loader, ele)} className="loaderNew" style={show()}>
            <div className="loader-imgNew"></div>
        </div>
    );
};
export const TextmaktingUpdate = () => {
    const TextMarketingEnable = useSelector((state) => state.TextMarketing.TriggerTextMarketingEnable);
    const DeactiveAisetting = useSelector((state) => state.TextMarketing.DeactiveAi);
    const { Status, Activate } = useSelector((state) => state.Settings);
    const { TextMarketingEnable: TextMarketing_Enable } = useSelector((state) => state.TextMarketing);
    const { isFromConnectApp } = useVagaroAIContext();
    const dispatch = useDispatch();
    
    useEffect(() => {
        if (isFromConnectApp) {
            if (TextMarketingEnable && Status === StatusEnum.Sucess && Activate === false) {
                console.trace('TextMarketingEnable');
                dispatch(TriggerEnableTextMaketing());
                dispatch(setActivate(true));
                ThunkAPI(dispatch, ThunkApiEnum.UpdateSetting);
            }
            if (!DeactiveAisetting && TextMarketing_Enable === false && Status === StatusEnum.Sucess && Activate === true) {
                console.trace('DeactiveAisetting');
                dispatch(DeactiveAi(true));
                dispatch(setActivate(false));
                ThunkAPI(dispatch, ThunkApiEnum.UpdateSetting);
            }
        }
    }, [dispatch, TextMarketingEnable, Status, Activate, TextMarketing_Enable]);
    return <></>
}