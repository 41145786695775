import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { AISlideContent, UpgradeSlideContent } from '../../Vagaroconnect/setting/SettingComponents/Components';
import { SliderSettings, StatusEnum } from '../../Vagaroconnect/Binding/ChatBoatEnums';
import { useSelector } from 'react-redux';

const SliderComponent = (props) => {
  const { AI, UpgradeTextMarketing } = props;
  const AI_Activat_Status = useSelector((state) => state.Settings.Activate !== true && state.Settings.Status === StatusEnum.Sucess);
  const TextMarketing_Status = useSelector((state) => state.TextMarketing.Status !== StatusEnum.Idle && state.TextMarketing.TextMarketingEnable !== true);
  const slides = [];
  if (AI && AI_Activat_Status) {
    slides.push({
      id: 2,
      content: <AISlideContent />,
    });
  }
  if (UpgradeTextMarketing && TextMarketing_Status) {
    slides.push({
      id: 1,
      content: <UpgradeSlideContent {...props} />
    })
  }

  const SliderWrap = ({ children, slides }) => {
    if (slides?.length > 1) {
      return (
        <Slider {...SliderSettings} className="bannerslider">
          {children}
        </Slider>
      );
    } else {
      return <div>{children}</div>;
    }
  };

  return (
    <div className='slider-space hideOncollapse' style={{ maxWidth: '172px' }}>
      <SliderWrap slides={slides}>
        {slides.map(slide => (
          <div key={slide.id}>
            {slide.content}
          </div>
        ))}
      </SliderWrap>
    </div>
  );
};

export default SliderComponent;


