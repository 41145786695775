import axios from "axios";
import { VagaroConnectApiUrl, ApiV2Url, SignalRUrl } from '../Common/APIURL';

window.VagaroCoreAPIURLV2 = (typeof (window) != "undefined" && typeof (window.VagaroCoreAPIURLV2) != "undefined") ?
    window.VagaroCoreAPIURLV2 : ApiV2Url;

window.VagaroCoreAPIURLConnect = (typeof (window) != "undefined" && typeof (window.VagaroCoreAPIURLConnect) != "undefined") ?
    window.VagaroCoreAPIURLConnect : VagaroConnectApiUrl;

window.VagaroConnectSignalRURL = (typeof (window) != "undefined" && typeof (window.VagaroConnectSignalRURL) != "undefined") ?
    window.VagaroConnectSignalRURL : SignalRUrl;


window.encMerchantId = (typeof (window) != "undefined" && typeof (window.MerchantId) != "undefined") ? window.MerchantId : "zPEuynUoyzU9qbHtaYZBIS";

const HeaderDetail = {
    encStaticMerchantid: "",
    encStaticUserid: ""
}

async function AsyncAjaxPost(Url, data, access_token, callback, onFailure, connectAPI = false, cancelToken = null) {
    var APIURL = window.VagaroCoreAPIURLV2 + Url;
    if (connectAPI) {
        APIURL = window.VagaroCoreAPIURLConnect + Url;
    } else {
        APIURL = window.VagaroCoreAPIURLV2 + Url;
    }
    const header = {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + access_token,
        "MerchantId": HeaderDetail.encStaticMerchantid,
        "UserId": HeaderDetail.encStaticUserid
    };
    await axios.post(APIURL, data, { headers: header, cancelToken: cancelToken })
        .then((response) => { callback(response) })
        .catch((error) => { onFailure(error) });
};

async function AsyncAjaxGet(Url, access_token, callback, onFailure, connectAPI = false, cancelToken = null) {
    var APIURL = window.VagaroCoreAPIURLV2 + Url;
    if (connectAPI) {
        APIURL = window.VagaroCoreAPIURLConnect + Url;
    } else {
        APIURL = window.VagaroCoreAPIURLV2 + Url;
    }
    const header = {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + access_token,
        "MerchantId": HeaderDetail.encStaticMerchantid,
        "UserId": HeaderDetail.encStaticUserid
    };
    await axios.get(APIURL, { headers: header, cancelToken: cancelToken })
        .then((response) => { callback(response) })
        .catch((error) => { onFailure(error) });
};

async function AsyncAjaxStaticGet(Url, access_token, callback, onFailure, connectAPI = false, cancelToken = null) {
    var APIURL = window.VagaroCoreAPIURLV2 + Url;
    if (connectAPI) {
        APIURL = window.VagaroCoreAPIURLConnect + Url;
    } else {
        APIURL = window.VagaroCoreAPIURLV2 + Url;
    }
    const header = {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + access_token,
        "MerchantId": HeaderDetail.encStaticMerchantid,
        "UserId": HeaderDetail.encStaticUserid
    };
    await axios.get(APIURL, { headers: header, cancelToken: cancelToken })
        .then((response) => { callback(response) })
        .catch((error) => { onFailure(error) });
};

async function AsyncAjaxGetWithBody(Url, access_token, callback, onFailure, connectAPI = false) {
    var APIURL = window.VagaroCoreAPIURLV2 + Url;
    if (connectAPI) {
        APIURL = window.VagaroCoreAPIURLConnect + Url;
    } else {
        APIURL = window.VagaroCoreAPIURLV2 + Url;
    }
    const headers = {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + access_token
    };
    await axios.get(APIURL, { headers: headers })
        .then((response) => { callback(response) })
        .catch((error) => { onFailure(error) });
};

async function AsyncAjaxPut(Url, access_token, data, callback, onFailure, connectAPI = false) {
    var APIURL = window.VagaroCoreAPIURLV2 + Url;
    if (connectAPI) {
        APIURL = window.VagaroCoreAPIURLConnect + Url;
    } else {
        APIURL = window.VagaroCoreAPIURLV2 + Url;
    }
    const headers = {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + access_token,
        "MerchantId": HeaderDetail.encStaticMerchantid,
        "UserId": HeaderDetail.encStaticUserid
    };
    await axios.put(APIURL, data, { headers: headers })
        .then((response) => { callback(response) })
        .catch((error) => { onFailure(error) });
};

async function AsyncAjaxDelete(Url, data, access_token, callback, onFailure, connectAPI = false) {
    var APIURL = window.VagaroCoreAPIURLV2 + Url;
    if (connectAPI) {
        APIURL = window.VagaroCoreAPIURLConnect + Url;
    } else {
        APIURL = window.VagaroCoreAPIURLV2 + Url;
    }
    const headers = {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + access_token,
        "MerchantId": HeaderDetail.encStaticMerchantid,
        "UserId": HeaderDetail.encStaticUserid
    };
    await axios.delete(APIURL, data, { headers: headers })
        .then((response) => { callback(response) })
        .catch((error) => { onFailure(error) });
};
function SyncAjaxDelete(Url, data, access_token, callback, onFailure, connectAPI = false) {
    var APIURL = window.VagaroCoreAPIURLV2 + Url;
    if (connectAPI) {
        APIURL = window.VagaroCoreAPIURLConnect + Url;
    } else {
        APIURL = window.VagaroCoreAPIURLV2 + Url;
    }
    const headers = {
        "Content-Type": "application/json",
        //"Authorization": "Bearer " + access_token
    };
    axios.delete(APIURL, data, { headers: headers })
        .then((response) => { callback(response) })
        .catch((error) => { onFailure(error) });
};

async function AsyncAjaxPatch(Url, data, access_token, callback, onFailure, connectAPI = false) {
    var APIURL = window.VagaroCoreAPIURLV2 + Url;
    if (connectAPI) {
        APIURL = window.VagaroCoreAPIURLConnect + Url;
    } else {
        APIURL = window.VagaroCoreAPIURLV2 + Url;
    }
    const headers = {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + access_token,
        "MerchantId": HeaderDetail.encStaticMerchantid,
        "UserId": HeaderDetail.encStaticUserid
    };
    await axios.patch(APIURL, data, { headers: headers })
        .then((response) => { callback(response) })
        .catch((error) => { onFailure(error) });
};

async function AjaxGenerateToken(Url, data, callback, onFailure, connectAPI = false) {
    var APIURL = window.VagaroCoreAPIURLV2 + Url;
    if (connectAPI) {
        APIURL = window.VagaroCoreAPIURLConnect + Url;
    } else {
        APIURL = window.VagaroCoreAPIURLV2 + Url;
    }
    const header = {
        "Content-Type": "application/json",
    };
    await axios.post(APIURL, data, { headers: header })
        .then((response) => { callback(response) })
        .catch((error) => { onFailure(error) });
};

export { AsyncAjaxPost, AsyncAjaxGet, AsyncAjaxPut, AsyncAjaxDelete, AsyncAjaxPatch, AsyncAjaxGetWithBody, AjaxGenerateToken, SyncAjaxDelete, HeaderDetail, AsyncAjaxStaticGet };
