import { fetchAISettings } from "../Common/AsyncThunk";
import { StatusEnum } from "../../Vagaroconnect/Binding/ChatBoatEnums";

export const BusinessSetting = {
    setStatus: (state, action) => {
        state.Status = action.payload
    },
    setActivate: (state, action) => {
        state.Activate = action.payload
    },
    setPause: (state, action) => {
        state.Pause = action.payload
    },
    SetSettingControl: (state, action) => {
        switch (action.payload) {
            case "replyToAll":
                state.AISettings = 1;
                break;
            case "replyDuringOffHours":
                state.AISettings = 2;
                break;
            case "replyDuringCustomHours":
                state.AISettings = 3;
                break;
            default:
                break;
        }
    },
    toggleDay: (state, action) => { 
        const day = action.payload;
        state.selectedDay = day;
    },
    setSelectedDay: (state, action) => {
        state.selectedDay = action.payload;
    },
    toggleWorkingDaysVisibility: (state) => {
        const { closeTime, openTime, activate } = state.CustomHours.find(hours => hours.name === state.selectedDay);
        if (state.showWorkingDays === false) {
            state.PrevshowWorkingDays.activate = activate;
            state.PrevshowWorkingDays.closeTime = closeTime;
            state.PrevshowWorkingDays.openTime = openTime;
        } else {
            state.CustomHours = state.CustomHours.map(arr => arr.name === state.selectedDay ? { ...arr, activate: state.PrevshowWorkingDays.activate, openTime: state.PrevshowWorkingDays.openTime, closeTime: state.PrevshowWorkingDays.closeTime } : { ...arr })
        }
        state.showWorkingDays = !state.showWorkingDays;
    },
    toggleWorkingDays: (state) => {
        state.showWorkingDays = !state.showWorkingDays;
    },

    toggleCustomHours: (state, action) => {
        const { id, value } = action.payload;
        state.CustomHours = state.CustomHours.map(arr => arr.dayID === id ? { ...arr, activate: value } : { ...arr })
    },
    handleCustomHours: (state, action) => {
        const { id, value, isOpenTime } = action.payload;
       if (isOpenTime) {
        state.CustomHours[id].openTime = value

       }
       else{
        state.CustomHours[id].closeTime = value

       }
    },
    ResetCustomHours: (state, action) => {
        const { id } = action.payload;
        state.CustomHours[id].openTime = "Start"
        state.CustomHours[id].closeTime = "End"
        state.CustomHoursValidation[id] = ""
    },
    SetHoursValidation: (state, action) => {
        const { id, value } = action.payload;
        if(state.CustomHours[id].activate){
            state.CustomHoursValidation[id] = value
        }else{
            state.CustomHoursValidation[id] = ""
        }
    },
    HandleCopyToAll: (state, action) => {
        const { id } = action.payload;
        const { closeTime, openTime, activate } = state.CustomHours[id];
        const validationMessage = state.CustomHoursValidation[id];
        state.CustomHours.forEach((day, index) => {
            day.closeTime = closeTime;
            day.openTime = openTime;
            day.activate = activate;
            state.CustomHoursValidation[index] = '';
            if (validationMessage) {
                state.CustomHoursValidation[index] = validationMessage;
            }
        });
    },
    updateCustomHoursFromResponse: (state, action) => {
        const { time, actionTag } = action.payload;
        const dayIndex = state.CustomHours.findIndex(day => day.name === state.selectedDay);
        if (dayIndex !== -1) {
            if (actionTag == 1) {
                state.CustomHours[dayIndex].closeTime = time;
            } else {
                state.CustomHours[dayIndex].openTime = time;
            }
        }
    },
};

export const ExtraReducersSettings = (builder) => {
    builder
        .addCase(fetchAISettings.pending, (state, action) => { state.Status = StatusEnum.Loading })
        .addCase(fetchAISettings.fulfilled, (state, action) => {     
            state.Status = StatusEnum.Sucess
            if (typeof action.payload !== 'undefined') {
                if (typeof action.payload?.active !== 'undefined') {
                    state.Activate = action.payload.active;
                }
                if (typeof action.payload?.aiReplySettings !== 'undefined') {
                    state.AISettings = action.payload.aiReplySettings;
                }
                if (typeof action.payload?.isPause !== 'undefined') {
                    state.Pause = action.payload.isPause;
                }
                if (typeof action.payload?.customHours !== 'undefined' && action.payload?.customHours.length > 0) {
                    if (action?.payload?.customHours) {
                        const updatedCustomHours = action.payload?.customHours || [];
                        state.CustomHours = state.CustomHours.map((day) => {
                            const updatedDay = updatedCustomHours.find(item => item.dayID === day.dayID);
                            if (updatedDay) {
                                return {
                                    ...day,
                                    openTime: updatedDay.openTime || day.openTime,
                                    closeTime: updatedDay.closeTime || day.closeTime,
                                    activate: true
                                };
                            } else {
                                return {
                                    ...day,
                                    openTime: "Start",
                                    closeTime: "End",
                                    activate: false
                                };
                            }
                        });
                    }
                }
                if (typeof action.payload?.businessoffhours !== 'undefined' && action.payload?.businessoffhours.length > 0) {
                    if (action?.payload?.businessoffhours) state.businessoffhours = action.payload.businessoffhours;
                }
                if (typeof action.payload?.userAccess !== 'undefined') {
                    state.UserAccess = action.payload.userAccess;
                }
                if (typeof action.payload?.currencySymbol !== 'undefined') {
                    state.currencySymbol = action.payload.currencySymbol;
                }
                if (typeof action.payload?.aiConnectPrice !== 'undefined') {
                    state.aiConnectPrice = action.payload.aiConnectPrice;
                }
            }
        })
};